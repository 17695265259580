import React, { createContext, useEffect, useState } from "react";
import MainLayout from "components/layout";
import fetch from 'isomorphic-fetch'
import { isAuthenticated, accessDenied } from 'components/Helpers/ShopifyAuth';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'


export const UserContext = createContext();

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://carbon-kitchen.myshopify.com/api/2021-07/graphql`,
  fetchOptions: {
    mode: 'no-cors',
  },
  headers: {
    'X-Shopify-Storefront-Access-Token': 'dd6358b117f38fc4bddf81eed7613ed4',
    'Accept': 'application/json',
  },
  fetch
})


const AuthWrapper = ({children}) => {
  const [isLoggedIn, logUserIn] = useState(false);
  
  useEffect(() => {
    if (isAuthenticated()) {
      logUserIn(true)
    }
  }, [])
  
  return ( 
    <MainLayout>
      <ApolloProvider client={apolloClient}>
        <UserContext.Provider value={{isLoggedIn, logUserIn}}>
          { children }
        </UserContext.Provider>
      </ApolloProvider>
    </MainLayout>
  )
  
}

export default AuthWrapper;
