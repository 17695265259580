import React from 'react';

import { h1, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import Button from 'components/Theme/Button';

const AffiliateSignupContainer = styled.div`
  padding:1.5rem;
`;

const AffiliateSignupHeadline = styled(body)`
  color:${p => p.theme.black};
  font-size:1.6rem;
`;

const AffiliateSignupLink = styled(Button)`

`;

const AffiliateSignup = () => {
  
  return (

    <AffiliateSignupContainer>
      <AffiliateSignupHeadline>Refer Carbon. Get Paid.</AffiliateSignupHeadline>
      <AffiliateSignupLink small={true} to={`/affiliate`}>Sign up</AffiliateSignupLink>
    </AffiliateSignupContainer>
  )
  
}

export default AffiliateSignup