import React from 'react';

import AffiliateSignup from './AffiliateSignup';

import { getToken, logoutCustomer } from 'components/Helpers/ShopifyAuth';

import { h1, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Link } from 'gatsby';
import Button from 'components/Theme/Button';


const NavContainer = styled.div`
  display:flex;
  flex-direction:column;
  margin:0 3rem 0 0;
  padding-top:3rem;
  width:30rem;
  border-radius:0.5rem;
  ${media.lessThan("tablet")`
    flex-direction:column;
    width:100%;
    margin:0 0 6rem 0;
  `}
`;

const NavLink = styled(Link)`
  font-size:1.8rem;
  font-weight:300;
  display:block;
  padding:0 0 0 1.5rem;
  margin-bottom:1.5rem;
  ${p => p.selected ? 'font-weight:bold;' : null};
  color:${p => p.theme.black};
  cursor:pointer;
`;

const Hr = styled.div`
  height:0.1rem;
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};
  margin:1.5rem 0;
`;

const Nav = ({path}) => {
  
  return (
    <NavContainer>
      <NavLink to={`/account`} selected={path === `/account`}>My Gear</NavLink>
      <NavLink to={`/account/orders`} selected={path === `/account/orders`}>Order History</NavLink>
      <NavLink to={`/account/addresses`} selected={path === `/account/addresses`}>Addresses</NavLink>
      <NavLink><a onClick={logoutCustomer}>Logout</a></NavLink>

      <Hr />
      <AffiliateSignup />
    </NavContainer>
  )
  
}

export default Nav