import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";

// Helpers
import { getToken } from 'components/Helpers/ShopifyAuth';

// Style
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h5, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

// Components
import AddressCard from "./AddressCard";
import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';
import { MdWarning } from "react-icons/md";


// CSS
const AddressContainer = styled(Container)`  
  position:relative;
  flex-direction: column;
  margin:3rem 0;
`;

const NewAddressContainer = styled(AddressContainer)`
  display:${p => p.isAdding ? 'block' : 'none'};
`;


const NewAddressCard = styled(Card)`
  flex-direction:column;
`;

const AddAddress = styled(Button)`

`;

const Headline = styled(h1)`
  font-size:4rem;
  color:${p => p.theme.black};
`;

const NewAddress = styled(body)`
  display:${p => p.isAdding ? 'block' : 'none'};
`;


const Street = styled(body)`

`;

const CityStateZip = styled(body)`
  font-size:1.4rem;
  margin-bottom:3rem;
`;

const Controls = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: ${p => p.justifyContent || "flex-start"};
`;

const CancelButton = styled(Button)`
  background-color:${p => p.theme.headerBgColor};
  color:${p => p.theme.cardTextColor};
  border:0.1rem solid ${p => p.theme.cardBorderColor};
  margin-left:1.5rem;
  &:hover {
    background-color:${p => p.theme.headerBgColor};
  }
`;

const Errors = styled.ul`
  background-color:${p => p.theme.cardBgColorDark};
  padding:1.5rem;
  border-radius: 0.5rem;
  margin-bottom:2rem;
`;

const Error = styled.li`
  color:${p => p.theme.cardTextColor};

  svg {
    color:${p => p.theme.cardTextColor};
  }
`;

const NoAddresses = styled(body)`
  
`;

const GET_CUSTOMER = gql`
  query Customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      defaultAddress {
        id
      }
      addresses(first: 10, reverse: true) {
        edges {
          node {
            id
            address1
            address2
            city
            company
            country
            countryCodeV2
            firstName
            lastName
            phone
            province
            zip
          }
        }
      }
    }
  }
`;

const ADD_ADDRESS = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const Addresses = () => {
  // component state
  const [isAdding, setAdding] = useState(false);
  const [hasErrors, setHasErrors] = useState([]);
  
  // queries and mutations
  const { data, refetch } = useQuery(GET_CUSTOMER, {variables: { customerAccessToken: getToken()}});
  const [addAddress] = useMutation(ADD_ADDRESS, { onCompleted: refetch });

  // form state
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [company, setCompany] = useState();
  const [province, setProvince] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [zip, setZip] = useState();

  

  const onSubmit = (e) => {
    e.preventDefault();
    
    var addressPayload = {
      address1: address1, 
      address2: address2, 
      city: city, 
      company: company,
      country: country,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      province : province,
      zip: zip
    }

    addAddress({ variables: { customerAccessToken: getToken(), address: addressPayload}}).then(async (resp) => {
      let { customerAddress, customerUserErrors } = resp.data.customerAddressCreate;
      
      if (customerUserErrors && customerUserErrors.length > 0) {
        let errors = customerUserErrors.map((error) => {
          return error.message
        })
  
        setHasErrors(errors)
      } else {
        setAdding(false)
      }
    })
  };

  return ( 
    <div>
      <Headline>Addresses</Headline>

      <AddressContainer>
        {data?.customer?.addresses?.edges.length == 0 &&
          <NoAddresses>
            You haven't added any addresses yet. Create one by clicking the button below.
          </NoAddresses>
        }

        {data && data.customer && data.customer.addresses.edges.map((address) => (
          <AddressCard address={address.node} defaultAddressId={data && data.customer.defaultAddress.id} />
        ))}
      </AddressContainer>

      <NewAddressContainer isAdding={isAdding}>
        <NewAddressCard>
          { hasErrors.length > 0 && 
            <Errors>
              {hasErrors.map((error) => (
                <Error><MdWarning /> {error}</Error>
              ))}
            </Errors>
          }
          <form onSubmit={onSubmit}>
            <div>
            <Street>
              <TextBox type="text" name="address1" placeholder="Street Address" required={true} value={address1} onChange={(e) => setAddress1(e.target.value) } />
            </Street>
            <CityStateZip>
              <TextBox type="text" name="city" placeholder="City" required={true} value={city} onChange={(e) => setCity(e.target.value) } />
              <TextBox type="text" name="province" placeholder="State" required={true} value={province} onChange={(e) => setProvince(e.target.value) }  /> 
              <TextBox type="text" name="zip" placeholder="Zip" required={true} value={zip} onChange={(e) => setZip(e.target.value) } />
              <TextBox type="text" name="country" placeholder="Country" required={true} value={country} onChange={(e) => setCountry(e.target.value) } />
            </CityStateZip>
            </div>
    
            <Controls>
              <Button type="submit">Submit</Button> 
              <CancelButton onClick={() => setAdding(false)}>Cancel</CancelButton> 
            </Controls>
          </form>
        </NewAddressCard>
      </NewAddressContainer>

      <AddAddress onClick={() => setAdding(true)}>Add Address</AddAddress>
    </div>
  )
  
}

export default Addresses;
