import React from "react";
import { useQuery, gql } from "@apollo/client";
import { getToken } from 'components/Helpers/ShopifyAuth';

import OrderCard from "./OrderCard";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Link } from "gatsby";

const Headline = styled(h1)`
  font-size:4rem;
  color:${p => p.theme.black};
`;

const OrdersContainer = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:3rem;
`;

const OrderWrapper = styled.div`
`;

const NoProducts = styled(body)`
  color:${p => p.theme.black};
  a {
    text-decoration: underline;
  }
`;


const GET_CUSTOMER = gql`
  query Customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      orders(first: 10) {
        edges {
          node {
            id
            name
            totalPrice
            fulfillmentStatus
            email
            lineItems(first: 100) {
              edges {
                node {
                  currentQuantity 
                  variant {
                    title
                    sku
                    image {
                      transformedSrc
                    }
                    product {
                      title 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Orders = (props) => {
  const { loading, error, data } = useQuery(GET_CUSTOMER, {variables: { customerAccessToken: getToken()}});  
  
  return ( 
    <div>
      <Headline>Order History</Headline>

      <OrdersContainer>
        {data?.customer?.orders?.edges.length == 0 &&
          <NoProducts>
            You don't own any Carbon products yet. Let's change that, <Link to="/products/carbon-oven">shop now</Link>.
          </NoProducts>
        }

        {((data && data.customer && data.customer.orders.edges) || []).map((order) => (
          <OrderWrapper>
            <OrderCard data={order.node} />
          </OrderWrapper>
        ))}
      </OrdersContainer>
    </div>
  )
  
}

export default Orders;
