import _ from 'lodash';

const consolidateOrdersToProducts = (orders) => {
  let products = getProducts(orders)
  return products;
  //return _.uniqBy(products, 'sku')
}

const getProducts = (orders) => {
  return _.flatten(orders.map((order) => {
    return reverseMapLineItems(order.node)
  }))
}

const reverseMapLineItems = (order) => {
  let lineItems = order.lineItems
  
  let variants = findVariants(lineItems)
  
  return variants.map(({variant, lineItem}) => {
    return {
      orderId: order.id,
      sku: variant.sku,
      variantId: variant.id,
      title: variant.title,
      imageSrc: variant.image.transformedSrc,
      product: variant.product,
    }
  })
}

const findVariants = (lineItems) => {
  return lineItems.edges.map((edge) => {
    let node = edge.node;
    

    if (node) {
      return {variant: node.variant, lineItem: node};
    }
  })
}


export default consolidateOrdersToProducts;