import React from 'react';
import { errorMessages } from "../Helpers/ShopifyGraphqlError";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

const Errors = styled.div`
  padding: 1.5rem;
  margin-bottom:1.5rem;
  background-color:${p => p.theme.cardBgColorDark};
  color:${p => p.theme.white};
  border-radius: 0.5rem;
`;

const Error = styled.div`
  font-size:1.2rem;
`;

const ErrorCard = ({errors}) => {

  return (
    <Errors>
      { errorMessages(errors).map((message, i) => (
        <Error key={`error_${i}`}>{message}</Error>
      ))}
    </Errors>
  )
  
}

export default ErrorCard