import React, { useContext } from "react";

// Helpers
import { useMutation, gql } from "@apollo/client";
import { UserContext } from './AuthWrapper';
import { authenticate } from "../Helpers/ShopifyAuth";


// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

// Components
import { Link } from "gatsby";
import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';
import ErrorCard from './ErrorCard';


const LoginCard = styled(Card)`
  background-color:${p => p.theme.cardBgColor};
  padding:12rem 6rem;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    padding:3rem;
  `}
`;


const Headline = styled(h4)`
  margin-bottom:3rem;
`;

const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:100%;
`;

const Field = styled.div`
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const ForgotPassword = styled(Link)`
  font-size:1.4rem;
`;

const Controls = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;


const AUTH_USER = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;


const Login = () => {
  let email, password;

  const { isLoggedIn } = useContext(UserContext);
  const [authUser, { data } ] = useMutation(AUTH_USER);

  if (data && data.customerAccessTokenCreate && data.customerAccessTokenCreate.customerAccessToken) {
    authenticate(data.customerAccessTokenCreate.customerAccessToken)
  }  
  
  const onSubmit = (e) => {
    e.preventDefault();
    authUser({ variables: { input: { email: email.value, password: password.value } }});
  };


  return ( 
    <LoginCard neumorphic>
      <Form onSubmit={onSubmit}>
        <Headline>Login</Headline>
        
        { data && <ErrorCard errors={data.customerAccessTokenCreate.customerUserErrors} /> }

        <Field>
          <TextBox type="email" ref={node => { email = node }} placeholder="Email"  />
        </Field>
        <Field>
          <TextBox type="password" ref={node => { password = node }} placeholder="Password" />
        </Field>

        <Controls>
          <Button>Login</Button>
          <ForgotPassword to={`/account/forgot-password`}>Forgot Password?</ForgotPassword>
        </Controls>
      </Form>
    </LoginCard>
  )
  
}

export default Login;
