import { navigate } from "gatsby"

export const accessToken = () => {
   let localStorageToken = typeof window !== `undefined` && window.localStorage.getItem('accessToken')
  
   if (localStorageToken) {
    return JSON.parse(localStorageToken)
   }
}

export const authenticate = (customerAccessToken) => {
  let tokenToJSON = JSON.stringify({ token: customerAccessToken.accessToken, expiresAt: customerAccessToken.expiresAt })
  typeof window !== `undefined` && window.localStorage.setItem('accessToken', tokenToJSON);
  navigate('/account')
}

export const isAuthenticated = () => {
  return getToken() && !isTokenExpired() ? true : false
}

export const getToken = () => {
  let JSONToken = accessToken()
  return JSONToken ? JSONToken.token : false
}

export const token = getToken();

export const isTokenExpired = () => {
  let JSONToken = accessToken()

  if (!JSONToken) {
    return true
  }

  let expiresAt = new Date(JSONToken.expiresAt)
  let now = new Date()

  return (expiresAt <= now) ? true : false
}

export const accessDenied = () => {
  let route = typeof window !== `undefined` && window.location.pathname

  if (route && route != '/account/login') {
    navigate('/account/login')
  }
}

export const logoutCustomer = () => {
  typeof window !== `undefined` && window.localStorage.removeItem('accessToken')
  navigate('/account/login')
}