import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Layout from "components/Account/Layout";
import { isAuthenticated } from "../Helpers/ShopifyAuth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (typeof window === `undefined`) {
    return null
  }

  let path = location.pathname

  

  if (!isAuthenticated() && (path !== `/account/login` || path !== `/account/forgot-password`)) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/account/login`)
    return null
  }

  return <Layout path={path}><Component {...rest} /></Layout>
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute