export const errorMessages = (errs) => {
  return errs.map((error) => {
    return humanReadable(error.message)
  })
}

const humanReadable = (message) => {
  switch(message) {
    case 'Unidentified customer':
      return 'Invalid username and/or password'
    case 'Invalid reset url':
      return 'That link has expired'
    default:
      return message
  }
}