import React, { useContext, useRef } from "react";

// Helpers
import { useMutation, gql } from "@apollo/client";
import { UserContext } from './AuthWrapper';
import { authenticate } from "../Helpers/ShopifyAuth";


// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

// Components
import { Link } from "gatsby";
import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';
import ErrorCard from './ErrorCard';


const LoginCard = styled(Card)`
  background-color:${p => p.theme.cardBgColor};
  padding:12rem 6rem;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    padding:3rem;
  `}
`;


const Headline = styled(h4)`
  margin-bottom:3rem;
`;

const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:100%;
`;

const Field = styled.div`
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const ForgotPassword = styled(Link)`
  font-size:1.4rem;
`;

const Controls = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;


const ACTIVATE_USER = gql`
  mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
      customer {
        id
        email
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;


const Activate = ({account_id, token}) => {
  const password = useRef(null);
  const { isLoggedIn } = useContext(UserContext);
  
  const [activateUser, customerData ] = useMutation(ACTIVATE_USER, { onCompleted(customerData) {
    console.log(customerData)
    if (customerData?.customerActivateByUrl?.customerUserErrors.length == 0) {
      authenticate(customerData?.customerActivateByUrl?.customerAccessToken)
    }
  }});
  
  const onSubmit = (e) => {
    e.preventDefault();
    activateUser({ variables: { password: password?.current?.value, activationUrl: `https://shop.cookwithcarbon.com/account/activate/${account_id}/${token}` }});
  };


  return ( 
    <LoginCard neumorphic>
      <Form onSubmit={onSubmit}>
        <Headline>Activate</Headline>
        
        { customerData?.data?.customerActivateByUrl?.customerUserErrors.length > 0 && <ErrorCard errors={customerData.data.customerActivateByUrl.customerUserErrors} /> }

        <Field>
          <TextBox type="password" ref={password} placeholder="Password" />
        </Field>

        <Controls>
          <Button>Activate</Button>
        </Controls>
      </Form>
    </LoginCard>
  )
  
}

export default Activate;
