import React, { useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { token } from 'components/Helpers/ShopifyAuth';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h5, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';


const AddressContainer = styled(Container)`
  position:relative;
  display:${p => p.isDeleted ? 'none' : 'block'};
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};
  margin-bottom:3rem;
  padding-bottom:3rem;

  &:last-child {
    border-bottom:0;
    margin-bottom:1rem;
    padding-bottom:1rem;
  }
`;

const MainCard = styled(Card)`
  flex-direction:column;
  margin-bottom:0;
`;

const DefaultAddress = styled(body)`
  border:0.1rem solid ${p => p.theme.cardBorderColor};
  border-radius:0.5rem;
  padding:1rem;
  line-height:2.2rem;
  font-size:1.8rem;
  color:${p => p.theme.black};
`;

const Street = styled(body)`
  color:${p => p.theme.black};
`;

const CityStateZip = styled(body)`
  font-size:1.4rem;
  margin-bottom:3rem;
  color:${p => p.theme.black};
`;

const Controls = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: ${p => p.justifyContent || "flex-start"};
`;

const CancelButton = styled(Button)`
  border:0.1rem solid ${p => p.theme.cardBorderColor};
  margin-left:1.5rem;
`;

const Delete = styled(body)`
  display:inline-block;
  line-height:2.2rem;
  font-size:1.6rem;
  margin-left:0.8rem;
  color:${p => p.theme.black};
  a {
    color:${p => p.theme.brandPrimary};
    cursor:pointer;
  }
`;

const Confirmation = styled(body)`
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:${p => p.theme.headerBgColor};
  display:flex;
  justify-content: center;
  align-items: center;
  color:${p => p.theme.cardTextColor};
  z-index:99;
  flex-direction: column;
`;

const Yes = styled(Button)`

`;

const No = styled(Button)`

`;


const UPDATE_ADDRESS = gql`
  mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const DELETE_ADDRESSS = gql`
  mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        code
        field
        message
      }
      deletedCustomerAddressId
    }
  }
`;

const AddressCard = ({address, defaultAddressId}) => {
  const [isEditing, setEditing] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  const [address1, setAddress1] = useState(address.address1);
  const [address2, setAddress2] = useState(address.address2);
  const [city, setCity] = useState(address.city);
  const [country, setCountry] = useState(address.country);
  const [company, setCompany] = useState(address.company);
  const [province, setProvince] = useState(address.province);
  const [firstName, setFirstName] = useState(address.firstName);
  const [lastName, setLastName] = useState(address.lastName);
  const [phone, setPhone] = useState(address.phone);
  const [zip, setZip] = useState(address.zip);

  
  const [updateAddress, { updateResp } ] = useMutation(UPDATE_ADDRESS);
  const [deleteAddress, { delResp } ] = useMutation(DELETE_ADDRESSS);

  const onSubmit = (e) => {
    e.preventDefault();
    
    var addressPayload = {
      address1: address1, 
      address2: address2, 
      city: city, 
      company: company,
      country: country,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      province : province,
      zip: zip
    }

    updateAddress({ variables: { customerAccessToken: token, id: address.id, address: addressPayload}});
    setEditing(false)
  };

  const confirmDeleteAddress = () => {
    deleteAddress({ variables: { customerAccessToken: token, id: address.id}});
    console.log(delResp)
    setDeleted(true)
  }

  return ( 
    <AddressContainer isDeleted={isDeleted}>
      { isDeleting && <ConfirmationBox confirmYes={confirmDeleteAddress} goBack={() => setDeleting(false)} /> }

      <MainCard>
        { isEditing && 
          <div>
            <form onSubmit={onSubmit}>
              <div>
              <Street>
                <TextBox type="text" required={true} value={address1} onChange={(e) => setAddress1(e.target.value) } />
              </Street>
              <CityStateZip>
                <TextBox type="text" required={true} value={city} onChange={(e) => setCity(e.target.value) } />
                <TextBox type="text" required={true} value={province} onChange={(e) => setProvince(e.target.value) }  /> 
                <TextBox type="text" required={true} value={zip} onChange={(e) => setZip(e.target.value) } />
              </CityStateZip>
              </div>
      
              <Controls>
                <Button type="submit" small={true}>Submit</Button> 
                <CancelButton onClick={() => setEditing(false)} invert={true} small={true}>Cancel</CancelButton> 
              </Controls>
            </form>
          </div>
        }
        { !isEditing && 
          <div>
            <Street>{address1}</Street>
            <CityStateZip>
              {city}, {province} {zip}
            </CityStateZip>
      
            <Controls justifyContent="space-between">
              <div>
                <Button onClick={() => setEditing(true)} small={true}>Edit</Button> 
                <Delete>or <a onClick={() => setDeleting(true)}>Delete</a></Delete>
              </div>
              { defaultAddressId === address.id ? <DefaultAddress>Default</DefaultAddress> : null }
            </Controls>
          </div>
        }
      </MainCard>
    </AddressContainer>
  )
}

const ConfirmationBox = ({confirmYes, goBack}) => {
  return (
    <Confirmation>
      Are you sure? 
      <div>
        <Yes onClick={confirmYes} small={true}>Yes</Yes> <CancelButton onClick={goBack} small={true}>No</CancelButton>
      </div>
    </Confirmation>
  )
}

export default AddressCard;
