import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { token } from 'components/Helpers/ShopifyAuth';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2, h5, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import SupportForm from 'components/Blocks/SupportForm';

const Headline = styled(h1)`
  font-size:4rem;
  color:${p => p.theme.black};
`;

const Header = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  width:100%;
  padding:1.5rem 0;
  margin:3rem 0;
  color:${p => p.theme.black};
  border-radius:0.5rem;
`;

const ProductImage = styled.img`
  width:25rem;
`;


const ProductTitle = styled(h1)`
  text-align:center;
  font-size:2.8rem;
  color:${p => p.theme.black};
`;

const Form = styled(SupportForm)`
  padding:1.5rem;
`;

const GET_ORDER = gql`
  query Order($orderId: ID!) {
    node(id: $orderId) {
      ... on Order {
        id
        name
        totalPrice
        fulfillmentStatus
        email
        totalPriceV2 {
          amount 
          currencyCode
        }
        totalRefundedV2 {
          amount 
          currencyCode
        }
        totalShippingPriceV2 {
          amount 
          currencyCode
        }
        totalTaxV2 {
          amount 
          currencyCode
        }
        shippingAddress {
          address1 
          address2
          city
          company
          country
          name
          formattedArea 
          zip
          province
        }
        discountApplications(first: 100) {
          edges {
            node {
              allocationMethod 
              targetSelection 
              targetType 
              value {
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
         
        lineItems(first: 100) {
          edges {
            node {
              quantity 
              originalTotalPrice {
                amount 
                currencyCode
              }
              variant {
                id
                title
                sku
                image {
                  transformedSrc
                }
                product {
                  title 
                }
              }
            }
          }
        }
      }
    }
  }
`;

const findVariantFromOrder = (lineItems, variantId) => {
  let variant;

  if(!lineItems) {
    return null
  } 

  lineItems.edges.map((edge) => {
    let line_item = edge.node;
    
    if (line_item.variant.id == variantId) {
      variant = line_item.variant;
    }
  })

  return variant
}

const Support = ({id, variant_id}) => {
  const [variant, setVariant] = useState(null);
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { orderId: btoa(decodeURIComponent(id))},
    onCompleted: (d) => {
      setVariant(findVariantFromOrder(d?.node?.lineItems, decodeURIComponent(variant_id))) 
    }
  });  

  return ( 
    <div>
      <Headline>Support</Headline>
      { variant &&
        <div>
          <Header>
            <ProductImage src={variant.image.transformedSrc} />
            <ProductTitle>{variant.product.title} - {variant.title}</ProductTitle>
          </Header>
          
          <Form orderNumber={data.node.name} />
        </div>
      }
    </div>
  )
  
}

export default Support;
