import React from "react";
import { navigate } from "gatsby";
import { useMutation, gql } from "@apollo/client";
import { authenticate } from "../Helpers/ShopifyAuth";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';
import ErrorCard from './ErrorCard';

const LoginCard = styled(Card)`
  background-color:${p => p.theme.cardBgColor};
  padding:12rem 6rem;
  color:${p => p.theme.cardTextColor};
`;


const Headline = styled(h4)`
  margin-bottom:3rem;
`;

const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:100%;
`;

const Field = styled.div`
  width:40rem;
`;

const RESET_PASSWORD = gql`
  mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;


const ResetPassword = ({customer_id, reset_token}) => {
  let password, confirmPassword;
  const [resetPassword, { data } ] = useMutation(RESET_PASSWORD);

  if (data && data.customerResetByUrl.customerAccessToken) {
    authenticate(data.customerResetByUrl.customerAccessToken)
    navigate('/account')
  } 

  const onSubmit = (e) => {
    e.preventDefault();
    resetPassword({ variables: { resetUrl: `https://shop.cookwithcarbon.com/account/reset/${customer_id}/${reset_token}`, password: password.value }})
  };

  return ( 
    <LoginCard neumorphic>
      <Form onSubmit={onSubmit}>
        <Headline>Reset Password</Headline>
        
        { data && <ErrorCard errors={data.customerResetByUrl.customerUserErrors} /> }

        <Field>
          <TextBox type="password" ref={node => { password = node }} placeholder="New Password"  />
        </Field>
        <Field>
          <TextBox type="password" ref={node => { confirmPassword = node }} placeholder="Confirm Password"  />
        </Field>
        <Button type="submit">Reset Password</Button>
      </Form>
    </LoginCard>
  )
}

export default ResetPassword;
