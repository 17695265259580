import React from "react";
import { Router } from "@reach/router"

// Helpers
import PrivateRoute from "components/Account/PrivateRoute";

// Routes
import Login from "components/Account/Login";
import ForgotPassword from "components/Account/ForgotPassword";
import ResetPassword from "components/Account/ResetPassword";
import MyGear from "components/Account/MyGear";
import Order from "components/Account/Order";
import Orders from "components/Account/Orders";
import Addresses from "components/Account/Addresses";
import Support from "components/Account/Support";
import SignUp from "components/Account/SignUp";
import Activate from "components/Account/Activate";

import AuthWrapper from "components/Account/AuthWrapper";

const Account = () => {
  return ( 
    <AuthWrapper>
      <Router basepath="/account">
        <PrivateRoute path="/mygear" component={MyGear} default={true} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/orders/:id" component={Order} />
        <PrivateRoute path="/addresses" component={Addresses} />
        <PrivateRoute path="/orders/:id/support/:variant_id" component={Support} />
        
        
        <SignUp path="/signup" />
        <ForgotPassword path="/forgot-password" />
        <ResetPassword path="/reset/:customer_id/:reset_token" />
        <Activate path="/activate/:account_id/:token" />
        <Login path="/login" />
      </Router>
    </AuthWrapper>
  )
  
}

export default Account;
