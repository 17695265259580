import React from "react";
import { useQuery, gql } from "@apollo/client";
import { token } from 'components/Helpers/ShopifyAuth';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h2, h5, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

const OrderContainer = styled(Container)`
  flex-direction:column;
  width:100%;
  border:0.1rem solid ${p => p.theme.cardBorderColor};
  border-radius:0.5rem;
  margin-bottom:1.5rem;
  padding:3rem;
  ${media.lessThan("tablet")`
    padding:0;
    border:0;
  `}
`;


const OrderCard = styled(Card)`
  flex-direction:column;
`;

const OrderDetails = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:1.5rem;
`;

const FulfillmentStatus = styled(body)`
  background-color:${p => p.theme.cardBgColorDark};
  color:${p => p.theme.mediumWhite};
  font-size:1rem;
  padding:0rem 1rem;
`;


const OrderNumber = styled(h5)`
  color:${p => p.theme.black};
`;

const OrderTotal = styled.div`
  display:flex;
  justify-content: flex-end;
  flex-direction: column;
  
`;

const OrderTotalItem = styled(h5)`
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size:1.4rem;
  color:${p => p.theme.black};

  div {
    width:15rem;
    text-align:right;
    padding:0.5rem 0;
    &:last-child {
      margin-left:0.5rem;
      border-bottom:0.1rem dashed ${p => p.theme.cardBorderColor};
    }
  }
`;

const StageContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
  margin:1.5rem 0;
`;

const height = 1.5;

const Stage = styled.div`
  font-size:1rem;
  line-height:${height*2}rem;
  text-transform:uppercase;
  font-weight:${p => p.active ? 'bold' : 600 };
  position:relative;
  text-align:center;
  letter-spacing:-0.02em;
  color:${p => p.active || p.completed ? p.theme.bodyBg : p.theme.mediumGrey };
  width:33.33%;
  background-color:${p => p.active || p.completed ? p.theme.brandPrimaryTextColor : null };
  border-top:0.1rem solid ${p => p.theme.cardBorderColor};
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};

  &:first-child {
    border-left:0.1rem solid ${p => p.theme.cardBorderColor};
    border-radius:0.5rem 0 0 0.5rem;
  }

  &:last-child {
    border-right:0.1rem solid ${p => p.theme.cardBorderColor};
    border-radius:0 0.5rem 0.5rem 0;
  }

  &:after {
    ${p => p.caret && (p.active || p.completed) && `
      content: " ";
      width: 0; 
      height: 0; 
      position:absolute;
      right:-${height}rem;
      border-top: ${height}rem solid transparent;
      border-bottom: ${height}rem solid transparent;
      
      border-left: ${height}rem solid ${p.theme.brandPrimaryTextColor};
    `}
  }
`;

const LineItems = styled.div`
  display:table;
  margin:1.5rem 0;
  color:${p => p.theme.black};
`;

const LineItem = styled.div`
  display:table-row;
  padding:1.5rem;
`;

const LineItemCol = styled.div`
  display:table-cell;
  vertical-align: middle;
  padding:0.75rem;
`;

const LineItemProductImg = styled.img`
  width:10rem;
`;

const LineItemTitle = styled(h2)`
  font-size:1.2rem;
  color:${p => p.theme.mediumWhite};
`;

const LineItemHeader = styled.div`
  display:table-row;
  background-color:${p => p.theme.cardBgColorDark};
`;

const LineItemProductDescrip = styled(body)`
  font-size:1.4rem;
  line-height:1.4rem;
  color:${p => p.theme.black};
`;


const LineItemQty = styled(body)`
  font-size:1.4rem;
  color:${p => p.theme.black};
`;

const LineItemPrice = styled(body)`
  font-size:1.4rem;
  color:${p => p.theme.black};
`;

const Addresses = styled.div`
  margin:1.5rem 0;
`;

const Address = styled(body)`
  font-size:1.4rem;
  line-height:2rem;
  border-radius:0.5rem;
  padding:1.5rem;
  color:${p => p.theme.black};
  border: 0.1rem  solid ${p => p.theme.cardBorderColor};
`;

const SmallSubheader = styled(h2)`
  font-size:1.2rem;
  color:${p => p.theme.black};
  margin-bottom:0.5rem;
`;



const GET_ORDER = gql`
  query Order($orderId: ID!) {
    node(id: $orderId) {
      ... on Order {
        id
        name
        totalPrice
        fulfillmentStatus
        email
        totalPriceV2 {
          amount 
          currencyCode
        }
        totalRefundedV2 {
          amount 
          currencyCode
        }
        totalShippingPriceV2 {
          amount 
          currencyCode
        }
        totalTaxV2 {
          amount 
          currencyCode
        }
        shippingAddress {
          address1 
          address2
          city
          company
          country
          name
          formattedArea 
          zip
          province
        }
        discountApplications(first: 100) {
          edges {
            node {
              allocationMethod 
              targetSelection 
              targetType 
              value {
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
         
        lineItems(first: 100) {
          edges {
            node {
              quantity 
              originalTotalPrice {
                amount 
                currencyCode
              }
              variant {
                title
                sku
                image {
                  transformedSrc
                }
                product {
                  title 
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FufillmentStatus = ({status}) => {
  let openStatuses = ['OPEN', 'SCHEDULED', 'UNFULFILLED']
  let isOpen = openStatuses.includes(status)

  let inProgressStatuses = ['IN_PROGRESS', 'PENDING_FULFILLMENT']
  let isInProgress = inProgressStatuses.includes(status)

  let deliveredStatuses = ['FULFILLED', 'PARTIALLY_FULFILLED']
  let isDelivered = deliveredStatuses.includes(status)

  return (
    <StageContainer>
      <Stage active={ isOpen } completed={ isInProgress || isDelivered } caret>Ordered</Stage>
      <Stage active={ isInProgress } completed={ isDelivered } caret>On the way!</Stage>
      <Stage active={ isDelivered }>Delivered</Stage>
    </StageContainer>
  )
}

const Discount = ({value}) => {
  if (value) {
    switch (value.__typename) {
      case 'PricingPercentageValue':
        return `${value.percentage}% off`
      case 'MoneyV2':
        return `-$${value.amount}`
      default:
        return `-$${value.amount}`
    } 
  }
}




const Order = ({id}) => {
  const { loading, error, data } = useQuery(GET_ORDER, {variables: { orderId: btoa(decodeURIComponent(id))}});  
  
  return ( 
    <OrderContainer>
      { data &&  
        <OrderCard>
          <OrderDetails>
            <OrderNumber>Order {data.node.name}</OrderNumber>
            <FulfillmentStatus>{data.node.fulfillmentStatus.replace('_', ' ')}</FulfillmentStatus>
          </OrderDetails>

          <Addresses>
            <SmallSubheader>SHIP TO</SmallSubheader>
            <Address>
              <div>{data.node.shippingAddress.name}</div>
              {data.node.shippingAddress.address1}
              <div>{data.node.shippingAddress.formattedArea}</div>
              <div>{data.node.shippingAddress.zip}</div>
            </Address>
          </Addresses>

          <FufillmentStatus status={data.node.fulfillmentStatus} />

          <LineItems>
            <LineItemHeader>
              <LineItemCol></LineItemCol>
              <LineItemCol><LineItemTitle>Description</LineItemTitle></LineItemCol>
              <LineItemCol><LineItemTitle>Qty</LineItemTitle></LineItemCol>  
              <LineItemCol><LineItemTitle>Price</LineItemTitle></LineItemCol>
            </LineItemHeader>
            {data.node.lineItems.edges.map((lineItem) => (
              <LineItem>
                <LineItemCol style={{width: '10rem'}}>
                  <LineItemProductImg src={lineItem.node.variant.image.transformedSrc} />
                </LineItemCol>
                <LineItemCol>
                  <LineItemProductDescrip>{lineItem.node.variant.product.title} - {lineItem.node.variant.title}</LineItemProductDescrip>
                </LineItemCol>
                <LineItemCol>
                  <LineItemQty>{lineItem.node.quantity}</LineItemQty>
                </LineItemCol>  
                <LineItemCol style={{width: '10rem'}}>
                  <LineItemPrice>${lineItem.node.originalTotalPrice.amount}</LineItemPrice>
                </LineItemCol>
              </LineItem>
            ))}
          </LineItems>

          <OrderTotal>
            {data.node.discountApplications.edges.map((discount) => (
              <OrderTotalItem>
                <div>Discounts:</div> <div><Discount value={discount.node.value} /></div>
              </OrderTotalItem> 
            ))}
            <OrderTotalItem>
              <div>Shipping:</div> <div>${data.node.totalShippingPriceV2.amount}</div>
            </OrderTotalItem>
            <OrderTotalItem>
              <div>Tax:</div> <div>${data.node.totalTaxV2.amount}</div>
            </OrderTotalItem>
            <OrderTotalItem>
              <div>Total:</div> <div>${data.node.totalPriceV2.amount}</div>
            </OrderTotalItem>
          </OrderTotal>
          
        </OrderCard>
      }
    </OrderContainer>
  )
  
}

export default Order;
