import React from "react";
import { useQuery, gql } from "@apollo/client";
import { getToken, logoutCustomer } from 'components/Helpers/ShopifyAuth';

import { h1 } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import Nav from "./Nav";

const LayoutContainer = styled.div`
  background-color:#fff;
  padding:3rem;
`;

const MainContainer = styled.div`
  flex-direction:row;
  display:flex;
  justify-content:center;
  align-items:flex-start;
  margin:6rem 0;

  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const Welcome = styled(h1)`
  color:${p => p.theme.black};
`;

const Content = styled.div`
  width:100%;
`;

const Header = styled.div`
  display:flex;
  flex-direction: row;
  margin:3rem 0;
  align-items:baseline;
`;

const GET_CUSTOMER = gql`
  query Customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
    }
  }
`;

const Layout = ({children, path}) => {
  const { loading, error, data } = useQuery(GET_CUSTOMER, {variables: { customerAccessToken: getToken()}});

  return ( 
    <LayoutContainer>
      { data && data.customer && 
        <Header>
          <Welcome>{data.customer.firstName}'s Kitchen</Welcome> 
        </Header>
      }
      <MainContainer>
        <Nav path={path} />
        <Content>
          { children }
        </Content>
      </MainContainer>
    </LayoutContainer>
  )
  
}

export default Layout;
