import React, { useContext, useRef } from "react";

// Helpers
import { useMutation, gql } from "@apollo/client";
import { UserContext } from './AuthWrapper';
import { authenticate } from "../Helpers/ShopifyAuth";


// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

// Components
import { Link } from "gatsby";
import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';
import ErrorCard from './ErrorCard';


const LoginCard = styled(Card)`
  background-color:${p => p.theme.cardBgColor};
  padding:12rem 6rem;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    padding:3rem;
  `}
`;


const Headline = styled(h4)`
  margin-bottom:3rem;
`;

const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:100%;
`;

const Field = styled.div`
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const ForgotPassword = styled(Link)`
  font-size:1.4rem;
`;

const Controls = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  width:40rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;


const CREATE_USER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const AUTH_USER = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;


const SignUp = () => {
  const email = useRef(null);
  const password = useRef(null);
  const firstName = useRef(null);
  const lastName = useRef(null);

  const { isLoggedIn } = useContext(UserContext);

  const [authUser, authData ] = useMutation(AUTH_USER, { onCompleted(authData) {
    if (authData?.customerAccessTokenCreate?.customerAccessToken) {
      authenticate(authData.customerAccessTokenCreate.customerAccessToken)
    }  
  }});
  
  const [createUser, customerData ] = useMutation(CREATE_USER, { onCompleted(customerData) {
    if (customerData?.customerCreate?.customerUserErrors.length == 0) {
      authUser({ variables: { input: { email: email?.current?.value, password: password?.current?.value } }});
    }
  }});
  
  const onSubmit = (e) => {
    e.preventDefault();
    createUser({ variables: { input: { email: email?.current?.value, password: password?.current?.value, firstName: firstName?.current?.value, lastName: lastName?.current?.value } }});
  };


  return ( 
    <LoginCard neumorphic>
      <Form onSubmit={onSubmit}>
        <Headline>Signup</Headline>
        
        { customerData?.data?.customerCreate?.customerUserErrors.length > 0 && <ErrorCard errors={customerData.data.customerCreate.customerUserErrors} /> }

        <Field>
          <TextBox type="textbox" ref={firstName} placeholder="First Name"  />
        </Field>
        <Field>
          <TextBox type="textbox" ref={lastName} placeholder="Last Name"  />
        </Field>
        <Field>
          <TextBox type="email" ref={email} placeholder="Email"  />
        </Field>
        <Field>
          <TextBox type="password" ref={password} placeholder="Password" />
        </Field>

        <Controls>
          <Button>Sign Up</Button>
        </Controls>
      </Form>
    </LoginCard>
  )
  
}

export default SignUp;
