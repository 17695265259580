import React from "react";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import Button from 'components/Theme/Button';
import { Link, navigate } from "gatsby"

import { MdHelp } from "react-icons/md";
import { GiShoppingCart } from "react-icons/gi";

const ProductContainer = styled(Container)`
  flex-direction:column;
  border:0.1rem solid ${p => p.theme.mediumGrey};
  margin-bottom:1.5rem;
  margin-right:1.5rem;
  align-items:center;
  justify-content: center;
`;

const MainCard = styled(Card)`
  flex-direction:column;
  width:100%;
  margin-bottom:0;
  align-items:center;
  justify-content: center;
`;

const ProductMain = styled.div`
  padding:1.5rem;
`;

const ProductImage = styled.img`
  width:25rem;
`;

const ProductTitle = styled(body)`
  text-align:center;
  color:${p => p.theme.black};
  font-size:1.6rem;
`;

const Buttons = styled.div`
  display:flex;
  flex-direction: row;
  margin-top:1.5rem;
  
  width:100%;
  padding:0.5rem;
  justify-content: space-evenly;
`;

const GearLink = styled(Link)`
  color:${p => p.theme.black};
  font-size:1.12rem;
  margin-right:1rem;
  cursor:pointer;
  svg {
    color:${p => p.theme.cardTextColorDark};
  }
`;

const ProductCard = ({data}) => {
  const { imageSrc, title, product, orderId, variantId } = data;
  
  return ( 
    <ProductContainer>
      <MainCard>
        <ProductMain>
          <ProductImage src={imageSrc} />
          <ProductTitle>{product.title} - {title}</ProductTitle>
        </ProductMain>
        <Buttons>
          <GearLink to={`/account/orders/${encodeURIComponent(orderId)}`}><GiShoppingCart /> View Order</GearLink>
          <GearLink to={`/account/orders/${encodeURIComponent(orderId)}/support/${encodeURIComponent(variantId)}`}><MdHelp /> Get Support</GearLink>
        </Buttons>
      </MainCard>
    </ProductContainer>
  )
  
}

export default ProductCard;
