import React, { useContext, useState } from "react";
import { navigate } from "gatsby";

import { useMutation, gql } from "@apollo/client";
import { UserContext } from './AuthWrapper';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4, h5 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button';
import TextBox from 'components/Theme/TextBox';

const LoginCard = styled(Card)`
  background-color:${p => p.theme.cardBgColor};
  padding:12rem 6rem;
  color:${p => p.theme.cardTextColor};
`;


const Headline = styled(h4)`
  margin-bottom:3rem;
`;

const Subheadline = styled(h5)`
  
`;

const Form = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  width:100%;
`;

const Field = styled.div`
  width:40rem;
`;

const CheckEmail = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0 auto;
`;


const FORGOT_PASSWORD = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;


const ForgotPassword = () => {
  let email;
  const [checkEmail, setCheckEmail] = useState(false);
  const [forgotPassword, { data } ] = useMutation(FORGOT_PASSWORD);


  const onSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ variables: { email: email.value }})
    setCheckEmail(true)
  };

  return ( 
    <LoginCard neumorphic>
      { !checkEmail && 
        <Form onSubmit={onSubmit}>
          <Headline>Forgot Password?</Headline>
          {data && data.customerRecover.customerUserErrors.message}
          <Field>
            <TextBox type="email" ref={node => { email = node }} placeholder="Email"  />
          </Field>
          <Button>Reset Password</Button>
        </Form> 
      }

      { checkEmail && 
        <CheckEmail>
          <Headline>Check your email</Headline>
          <Subheadline>We sent you a link to reset your password.</Subheadline>
        </CheckEmail>
      }
    </LoginCard>
  )
  
}

export default ForgotPassword;
