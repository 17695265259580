import React from "react";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h5 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import { Link } from "gatsby";

const OrderContainer = styled(Container)`
  flex-direction:column;
  width:100%;
  border:0.1rem solid ${p => p.theme.cardBorderColor};
  border-radius:0.5rem;
  margin-bottom:1.5rem;
  padding:3rem;
`;


const MainCard = styled(Card)`
  flex-direction:column;
`;

const OrderDetails = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin-bottom:1.5rem;
`;

const OrderNumber = styled(h5)`
  color:${p => p.theme.black};
`;

const OrderTotal = styled(h5)`
  color:${p => p.theme.black};
`;


const StageContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top:1.5rem;
`;

const height = 1.5;

const Stage = styled.div`
  font-size:1rem;
  line-height:${height*2}rem;
  text-transform:uppercase;
  font-weight:600;
  position:relative;
  text-align:center;
  letter-spacing:-0.02em;
  color:${p => p.active || p.completed ? p.theme.bodyBg : p.theme.mediumGrey };
  width:33.33%;
  background-color:${p => p.active || p.completed ? p.theme.brandPrimaryTextColor : null };
  border-top:0.1rem solid ${p => p.theme.cardBorderColor};
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};

  &:first-child {
    border-left:0.1rem solid ${p => p.theme.cardBorderColor};
    border-radius:0.5rem 0 0 0.5rem;
  }

  &:last-child {
    border-right:0.1rem solid ${p => p.theme.cardBorderColor};
    border-radius:0 0.5rem 0.5rem 0;
  }

  &:after {
    ${p => p.caret && (p.active || p.completed) && `
      content: " ";
      width: 0; 
      height: 0; 
      position:absolute;
      right:-${height}rem;
      border-top: ${height}rem solid transparent;
      border-bottom: ${height}rem solid transparent;
      
      border-left: ${height}rem solid ${p.theme.brandPrimaryTextColor};
    `}
  }
`;

const FufillmentStatus = ({status}) => {
  let openStatuses = ['OPEN', 'SCHEDULED', 'UNFULFILLED']
  let isOpen = openStatuses.includes(status)

  let inProgressStatuses = ['IN_PROGRESS', 'PENDING_FULFILLMENT']
  let isInProgress = inProgressStatuses.includes(status)

  let deliveredStatuses = ['FULFILLED', 'PARTIALLY_FULFILLED']
  let isDelivered = deliveredStatuses.includes(status)

  return (
    <StageContainer>
      <Stage active={ isOpen } completed={ isInProgress || isDelivered } caret>Ordered</Stage>
      <Stage active={ isInProgress } completed={ isDelivered } caret>On the way!</Stage>
      <Stage active={ isDelivered }>Delivered</Stage>
    </StageContainer>
  )
}


const OrderCard = ({data}) => {
  const { id, fulfillmentStatus, name, totalPrice, lineItems } = data;
  
  return ( 
    <OrderContainer>
      <MainCard>
        <OrderDetails>
          <OrderNumber><Link to={`/account/orders/${id}`}>Order {name}</Link></OrderNumber>
          <OrderTotal>Total: ${totalPrice}</OrderTotal>
        </OrderDetails>
        <FufillmentStatus status={fulfillmentStatus} />
      </MainCard>
    </OrderContainer>
  )
  
}

export default OrderCard;
